import { useLayoutEffect } from 'react';
import Cookies from 'js-cookie';
import useI18n from '@i18n';

import OffersSlugPage from '../page';

const OffersPageEpn: React.FC = props => {
    const { locale } = useI18n();

    useLayoutEffect(() => {
        if (typeof window !== 'undefined' && !Cookies.get('jwt_refresh_token')) {
            window.location.href = `/${locale}`;
        }
    }, [locale]);

    return <OffersSlugPage {...props} />;
};

export default OffersPageEpn;
